.slick-prev:before,
.slick-next:before {
  color: #dcb029;
}
.slick-track {
  margin: 0;
}
/* .slick-prev {
  left: -20px !important;
}
.slick-next {
  right: -20px !important;
} */
.slick-prev {
  top: -22px;
  left: auto;
  right: 50px;
}
.slick-next {
  top: -22px;
  left: auto;
  right: 10px;
}
[dir="rtl"] .slick-prev {
  right: auto;
  top: -35px;
  left: 10px;
  transform: rotate(180deg);
}

[dir="rtl"] .slick-next {
  right: auto;
  top: -35px;
  left: 50px;
  transform: rotate(180deg);
}

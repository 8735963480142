body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #98989c;
  background: #d2d2d2;
  border-radius: 8px;
  display: none;
}
h1 {
  font-size: 27px;
  letter-spacing: 0.2px;
  font-weight: 600;
}
h2 {
  letter-spacing: 0.2px;
  font-weight: 600;
}
h3,
h4 {
  letter-spacing: 0.3px;
  font-weight: 500;
}
h5,
p,
span,
ul li,
ul li a,
input {
  font-weight: 400;
}
a {
  text-decoration: none;
  color: #000;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

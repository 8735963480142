.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.orderStatus {
  padding: 3px 15px 4px;
  border-radius: 20px;
  color: #fff;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.map-container .map {
  position: relative !important;
}
@keyframes slideRight {
  0% {
    right: -400px;
  }
  100% {
    right: 0;
  }
}
@keyframes slideRightMax {
  0% {
    right: -500px;
  }
  100% {
    right: 0;
  }
}
@keyframes slideRightMaxWidth {
  0% {
    right: -650px;
  }
  100% {
    right: 0;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.main-content {
  box-sizing: border-box;
  margin: 10px auto auto;
}
@media only screen and (max-width: 767px) {
  .main-content {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .main-content {
    min-width: 750px;
    padding: 0 50px;
    width: auto;
  }
}

@media only screen and (min-width: 992px) {
  .main-content {
    width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .main-content {
    width: 85%;
  }
}

.pac-container {
  z-index: 99999 !important;
}
.map-container .map {
  position: relative !important;
}

#carousel .css-175oi2r,
#carousel .css-view-175oi2r,
.modalRight .css-view-175oi2r,
.modalRight .css-175oi2r {
  min-width: 100%;
}

.css-view-175oi2r,
.css-175oi2r {
  width: 100%;
}
#catSidebar .css-view-175oi2r {
  width: auto;
}

body.ReactModal__Body--open {
  overflow: hidden;
}
.modal {
  position: fixed;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  padding: 20px 0;
  background: #fff;
  outline: 0;
  border-radius: 5px;
  max-height: 100vh;
}
.modal .header-logo {
  width: auto;
  max-height: 75px;
  max-width: 100%;
  margin: 15px 30px 5px;
}
.ReactModal__Content .modal-header {
  padding: 0 15px 13px;
  text-transform: capitalize;
  border-bottom: 5px solid #f4f5f9;
  display: flex;
  font-size: 1.1em;
  font-weight: 500;
  align-items: center;
  font-weight: 500;
}
.ReactModal__Content .modal-header .backIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 0 10px;
}
.ReactModal__Content .modal-body {
  padding: 16px 20px;
  max-height: calc(100vh - 90px);
  overflow: overlay;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}
.ReactModal__Overlay {
  opacity: 0;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  opacity: 0;
  transform: translate(50%, 50%);
  transition: opacity 0.5s ease-in-out;
}
.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(0px, 0);
}
.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateX(50%, 50%);
}
.modalRight {
  right: 0;
  height: 100vh;
  border-radius: 0 !important;
}
.modalRight h3 {
  font-size: 20px;
  margin: 20px 15px;
}
.ReactModal__Content.modalRight {
  opacity: 0;
  transform: translate(25%, 0);
  transition: transform 0.3s ease-out;
}
.modalRight.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(0, 0);
}
.modalRight.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(25%, 0);
}
.ReactModal__Content.modalRight .modal-body {
  height: calc(100vh - 110px);
  overflow: overlay;
}
.modalBottom {
  bottom: 0;
  width: 100vw;
  border-radius: 0 !important;
}
.ReactModal__Content.modalBottom {
  opacity: 0;
  transform: translate(0, 25%);
  transition: transform 0.3s ease-out;
}
.modalBottom.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(0, 0);
}
.modalBottom.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(0, 25%);
}
